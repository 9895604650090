import {FC, useEffect, useRef} from 'react';
import './FastServiceSection.scss'
import fastserviceImg from '../../../assets/images/fastservice.png';

import {useTranslation, Trans} from "react-i18next";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {useDispatchEx} from "../../../hooks/redux";
import {anime1Finish, anime1Start} from "../../../helpers/animations";
const FastServiceSection:FC = () => {
    gsap.registerPlugin(ScrollTrigger);


    const targetSection = useRef(null);
    useEffect(()=>{

        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: targetSection.current,
                start: '+=20% bottom'
            }
        });

        timeline.fromTo(".fast-section__inner", anime1Start, anime1Finish, 0.1);
        timeline.fromTo(".fast-section__wrapper", anime1Start, anime1Finish, 0.4);


    },[targetSection]);
    const { t } = useTranslation();
    const dispatch = useDispatchEx();
    return (
        <section className={'fast-section'} ref={targetSection}>
            <div className="container">
                <div className="wrapper">
                    <div className="row">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="fast-section__inner">
                                <div className="fast-section__image">
                                    <img src={fastserviceImg} alt=""/>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="fast-section__wrapper">
                                <div className="fast-section__header">
                                    <h2 className="h2">
                                        Fast & convenient service for your staff <span>IN ONE APP</span>
                                    </h2>
                                </div>
                                <div className="fast-section__description">Waiter/manager/cooker/accountant</div>
                                <div className="fast-section__content">
                                    Don't waste extra time and money on maintenance. Quick Touch Waiter will handle everything for you, and you and your staff will receive online gratuities from your thankful customers.
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </section>
    );
};

export default FastServiceSection;