import {FC, useEffect, useRef} from 'react';
import './ReducingSection.scss'

import {useTranslation, Trans} from "react-i18next";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {useDispatchEx} from "../../../hooks/redux";
import Plate from "../../atoms/Plate/Plate";
import WhatsappIcon from "../../icons/place/WhatsappIcon";
import EquipmentIcon from "../../icons/reducing/EquipmentIcon";
import StaffIcon from "../../icons/reducing/StaffIcon";
import MarketingIcon from "../../icons/reducing/MarketingIcon";
import DeliveryIcon from "../../icons/reducing/DeliveryIcon";
import MaintenanceIcon from "../../icons/reducing/MaintenanceIcon";
import {anime1Finish, anime1Start} from "../../../helpers/animations";
const ReducingSection:FC = () => {
    gsap.registerPlugin(ScrollTrigger);


    const targetSection = useRef(null);
    useEffect(()=>{

        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: targetSection.current,
                start: '+=20% bottom'
            }
        });

        timeline.fromTo(".reducing-section__header", anime1Start, anime1Finish, 0.1);
        timeline.fromTo(".reducing-section__item", anime1Start, anime1Finish, 0.4);

    },[targetSection]);
    const { t } = useTranslation();
    const dispatch = useDispatchEx();
    return (
        <section className={'reducing-section'} ref={targetSection}>
            <div className="container">
                <div className="wrapper">
                    <div className="reducing-section__wrapper">
                        <div className="reducing-section__header">
                            <h2 className="h2">
                                Quick Touch Waiter helps reducing costs for
                            </h2>
                        </div>
                        <div className="reducing-section__items">
                            <div className="reducing-section__item">
                                <Plate
                                    type={'big'}
                                    icon={<EquipmentIcon/>}
                                    title={'Equipment'}
                                />
                            </div>
                            <div className="reducing-section__item">
                                <Plate
                                    type={'big'}
                                    icon={<StaffIcon/>}
                                    title={'Staff'}
                                />
                            </div>
                            <div className="reducing-section__item">
                                <Plate
                                    type={'big'}
                                    icon={<MarketingIcon/>}
                                    title={'Marketing and printing'}
                                />
                            </div>
                            <div className="reducing-section__item">
                                <Plate
                                    type={'big'}
                                    icon={<DeliveryIcon/>}
                                    title={'Delivery and booking'}
                                />
                            </div>
                            <div className="reducing-section__item">
                                <Plate
                                    type={'big'}
                                    icon={<MaintenanceIcon/>}
                                    title={'Maintenance'}
                                />
                            </div>
                            <div className="reducing-section__item">
                                <Plate
                                    type={'big'}
                                    icon={<WhatsappIcon/>}
                                    title={'Support'}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default ReducingSection;