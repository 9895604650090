import {FC, useEffect, useRef} from 'react';
import './GetAllSection.scss'
import getAllImg from '../../../assets/images/getall.png'
import {useTranslation, Trans} from "react-i18next";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {useDispatchEx} from "../../../hooks/redux";
import List from "../../atoms/List/List";
import {anime1Finish, anime1Start} from "../../../helpers/animations";
const GetAllSection:FC = () => {
    gsap.registerPlugin(ScrollTrigger);


    const targetSection = useRef(null);
    useEffect(()=>{

        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: targetSection.current,
                start: '+=20% bottom'
            }
        });
        timeline.fromTo(".get-all-section__header", anime1Start, anime1Finish, 0.1);
        timeline.fromTo(".get-all-section__item", anime1Start, anime1Finish, 0.4);
        timeline.fromTo(".get-all-section__inner", anime1Start, anime1Finish, 0.4);

    },[targetSection]);
    const { t } = useTranslation();
    const dispatch = useDispatchEx();
    return (
        <section className={'get-all-section'} ref={targetSection}>
            <div className="container">
                <div className="wrapper">
                    <div className="get-all-section__header">
                        <h2 className="h2"><span>GET</span> all restaurant management for free and instantly</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="get-all-section__wrapper">
                                <div className="get-all-section__items">
                                    <div className="get-all-section__item">
                                        <div className="get-all-section__item-title">Online menu</div>
                                        <div className="get-all-section__item-content">
                                            <List className={'list--default'} items={[
                                                'Scan',
                                                'Choose',
                                                'Order',
                                            ]}/>
                                            <p>Fast service without waiter</p>
                                        </div>
                                    </div>
                                    <div className="get-all-section__item">
                                        <div className="get-all-section__item-title">Online orders</div>
                                        <div className="get-all-section__item-content">
                                            <List className={'d-none d-md-flex list--default'} items={[
                                                'Dine in',
                                                'Preorder',
                                                'Take away',
                                                'Delivery'
                                            ]}/>
                                            <div className="get-all-section__item-list-mobile">
                                                <List className={'list--default'} items={[
                                                    'Dine in',
                                                    'Preorder',
                                                ]}/>
                                                <List className={'list--default'} items={[
                                                    'Take away',
                                                    'Delivery'
                                                ]}/>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="get-all-section__item">
                                        <div className="get-all-section__item-title">Online bookings</div>
                                        <div className="get-all-section__item-content">Table reservations with online deposits</div>
                                    </div>
                                    <div className="get-all-section__item">
                                        <div className="get-all-section__item-title">Online payment</div>
                                        <div className="get-all-section__item-content">
                                            <List className={'d-none d-md-flex list--default'} items={[
                                                'Pay & Go',
                                                'Online tips',
                                                'Online Deposits'
                                            ]}/>
                                            <div className="get-all-section__item-list-mobile">
                                                <List className={'list--default'} items={[
                                                    'Pay & Go',
                                                    'Online tips',
                                                ]}/>
                                                <List className={'list--default'} items={[
                                                    'Online Deposits'
                                                ]}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="get-all-section__inner">
                                <div className="get-all-section__image">
                                    <img src={getAllImg} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default GetAllSection;