import React from 'react';

const LogoTextIcon = () => {
    return (
        <>
            <svg width="283" height="32" viewBox="0 0 283 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M272.696 27.888C271.863 27.888 271.169 27.6986 270.613 27.3198C270.083 26.941 269.818 26.5243 269.818 26.0697V11.6371C269.818 11.1067 270.083 10.6774 270.613 10.3491C271.169 10.0208 271.863 9.85668 272.696 9.85668C273.429 9.85668 274.048 10.0208 274.553 10.3491C275.058 10.6774 275.31 11.1067 275.31 11.6371V13.0387C275.563 12.4831 275.916 11.9528 276.371 11.4477C276.851 10.9173 277.381 10.488 277.962 10.1597C278.568 9.80617 279.187 9.62939 279.818 9.62939H280.917C281.472 9.62939 281.952 9.88193 282.356 10.387C282.785 10.8921 283 11.4856 283 12.1674C283 12.8745 282.785 13.4806 282.356 13.9857C281.952 14.4908 281.472 14.7433 280.917 14.7433H279.818C279.136 14.7433 278.48 14.9832 277.848 15.4631C277.217 15.9176 276.699 16.5616 276.295 17.395C275.891 18.2284 275.689 19.2259 275.689 20.3876V26.0697C275.689 26.5243 275.399 26.941 274.818 27.3198C274.237 27.6986 273.53 27.888 272.696 27.888Z" fill="#346897"/>
                <path d="M259.928 28.2668C258.059 28.2668 256.405 27.9385 254.966 27.2819C253.526 26.6 252.39 25.653 251.556 24.4408C250.748 23.2286 250.344 21.827 250.344 20.236V17.4707C250.344 16.0565 250.736 14.7559 251.519 13.569C252.301 12.3821 253.349 11.4351 254.663 10.7279C255.976 9.99558 257.441 9.62939 259.057 9.62939C260.522 9.62939 261.86 9.93244 263.072 10.5385C264.31 11.1446 265.307 11.9906 266.065 13.0766C266.822 14.1372 267.201 15.3873 267.201 16.8268C267.201 17.9632 267.012 18.7839 266.633 19.289C266.279 19.7688 265.812 20.0719 265.231 20.1982C264.676 20.2992 264.108 20.3497 263.527 20.3497H256.216V20.577C256.216 21.5114 256.569 22.269 257.276 22.8498C257.984 23.4054 258.918 23.6832 260.08 23.6832C260.863 23.6832 261.532 23.5696 262.087 23.3423C262.643 23.0897 263.135 22.8498 263.565 22.6225C263.994 22.3953 264.411 22.2816 264.815 22.2816C265.269 22.2816 265.661 22.4331 265.989 22.7362C266.317 23.0392 266.557 23.3928 266.709 23.7969C266.886 24.2009 266.974 24.5545 266.974 24.8575C266.974 25.3373 266.684 25.8424 266.103 26.3728C265.522 26.9031 264.701 27.3577 263.64 27.7365C262.605 28.09 261.368 28.2668 259.928 28.2668ZM256.216 17.0541H260.913C261.191 17.0541 261.393 16.9909 261.519 16.8646C261.645 16.7384 261.709 16.4985 261.709 16.1449C261.709 15.6398 261.57 15.2231 261.292 14.8948C261.039 14.5413 260.698 14.2887 260.269 14.1372C259.865 13.9604 259.423 13.8721 258.943 13.8721C258.489 13.8721 258.047 13.9604 257.617 14.1372C257.213 14.2887 256.872 14.5413 256.595 14.8948C256.342 15.2231 256.216 15.6398 256.216 16.1449V17.0541Z" fill="#346897"/>
                <path d="M244.761 27.8873C242.362 27.8873 240.519 27.3822 239.231 26.3721C237.968 25.3367 237.337 23.7078 237.337 21.4854V3.79505C237.337 3.26472 237.652 2.8354 238.284 2.5071C238.915 2.1788 239.572 2.01465 240.253 2.01465C240.986 2.01465 241.655 2.1788 242.261 2.5071C242.892 2.8354 243.208 3.26472 243.208 3.79505V9.856H247.489C247.968 9.856 248.322 10.0833 248.549 10.5379C248.802 10.9672 248.928 11.4344 248.928 11.9394C248.928 12.4698 248.802 12.9496 248.549 13.3789C248.322 13.8082 247.968 14.0229 247.489 14.0229H243.208V21.4854C243.208 22.041 243.322 22.4325 243.549 22.6597C243.776 22.8618 244.18 22.9628 244.761 22.9628H246.39C247.249 22.9628 247.88 23.228 248.284 23.7583C248.688 24.2634 248.89 24.819 248.89 25.425C248.89 26.0564 248.688 26.6246 248.284 27.1297C247.88 27.6348 247.249 27.8873 246.39 27.8873H244.761Z" fill="#346897"/>
                <path d="M231.08 6.37205C230.221 6.37205 229.489 6.09425 228.883 5.53867C228.277 4.98308 227.974 4.35173 227.974 3.64462C227.974 2.91226 228.277 2.28091 228.883 1.75058C229.489 1.22024 230.221 0.955078 231.08 0.955078C231.939 0.955078 232.658 1.22024 233.239 1.75058C233.845 2.28091 234.148 2.91226 234.148 3.64462C234.148 4.35173 233.845 4.98308 233.239 5.53867C232.658 6.09425 231.939 6.37205 231.08 6.37205ZM231.08 27.8884C230.221 27.8884 229.514 27.699 228.959 27.3202C228.428 26.9414 228.163 26.5247 228.163 26.0701V11.6375C228.163 11.1072 228.428 10.6778 228.959 10.3495C229.514 10.0212 230.221 9.85709 231.08 9.85709C231.888 9.85709 232.583 10.0212 233.164 10.3495C233.744 10.6778 234.035 11.1072 234.035 11.6375V26.0701C234.035 26.5247 233.744 26.9414 233.164 27.3202C232.583 27.699 231.888 27.8884 231.08 27.8884Z" fill="#346897"/>
                <path d="M213.641 28.2668C212.328 28.2668 211.191 27.8627 210.232 27.0546C209.272 26.2212 208.792 24.9459 208.792 23.2286C208.792 21.7387 209.171 20.577 209.929 19.7436C210.686 18.9102 211.722 18.3294 213.035 18.0011C214.373 17.6728 215.914 17.5086 217.656 17.5086H219.02V17.1298C219.02 16.7005 218.932 16.2712 218.755 15.8419C218.578 15.4125 218.288 15.059 217.884 14.7812C217.48 14.4782 216.911 14.3266 216.179 14.3266C215.144 14.3266 214.373 14.4276 213.868 14.6297C213.363 14.8317 212.972 15.0464 212.694 15.2736C212.416 15.4757 212.101 15.5767 211.747 15.5767C211.116 15.5767 210.623 15.261 210.27 14.6297C209.941 13.9983 209.777 13.3417 209.777 12.6599C209.777 12.0538 210.118 11.5234 210.8 11.0689C211.507 10.6143 212.353 10.2607 213.338 10.0082C214.348 9.75566 215.32 9.62939 216.255 9.62939C218.326 9.62939 219.992 9.98295 221.255 10.6901C222.518 11.3972 223.44 12.3189 224.02 13.4554C224.601 14.5665 224.892 15.7661 224.892 17.0541V26.0697C224.892 26.5748 224.614 27.0041 224.058 27.3577C223.528 27.7112 222.846 27.888 222.013 27.888C221.28 27.888 220.662 27.7112 220.157 27.3577C219.652 27.0041 219.399 26.5748 219.399 26.0697V25.2363C218.717 26.0192 217.922 26.7263 217.013 27.3577C216.103 27.9638 214.98 28.2668 213.641 28.2668ZM216.179 23.9863C216.583 23.9863 217 23.8347 217.429 23.5317C217.859 23.2034 218.225 22.7741 218.528 22.2437C218.856 21.7134 219.02 21.1326 219.02 20.5012V20.3497H218.869C218.162 20.3497 217.48 20.4002 216.823 20.5012C216.192 20.577 215.674 20.7664 215.27 21.0694C214.891 21.3472 214.702 21.7892 214.702 22.3953C214.702 22.9508 214.841 23.3549 215.118 23.6074C215.396 23.86 215.75 23.9863 216.179 23.9863Z" fill="#346897"/>
                <path d="M187.731 28.1145C186.873 28.1145 186.052 27.9504 185.269 27.6221C184.511 27.2938 184.031 26.8013 183.829 26.1447L176.291 3.03736C176.241 2.88583 176.215 2.72168 176.215 2.5449C176.215 2.11559 176.417 1.72415 176.821 1.3706C177.226 1.01704 177.718 0.739247 178.299 0.537215C178.905 0.309931 179.486 0.196289 180.041 0.196289C180.496 0.196289 180.9 0.284677 181.254 0.461454C181.607 0.612977 181.834 0.890771 181.935 1.29483L187.693 20.7277L190.686 7.92399C190.812 7.41891 191.153 7.0401 191.709 6.78756C192.29 6.50977 192.896 6.37088 193.527 6.37088C194.184 6.37088 194.79 6.50977 195.345 6.78756C195.926 7.0401 196.267 7.41891 196.368 7.92399L199.361 20.7277L205.119 1.29483C205.245 0.890771 205.472 0.612977 205.8 0.461454C206.154 0.284677 206.558 0.196289 207.013 0.196289C207.593 0.196289 208.174 0.309931 208.755 0.537215C209.336 0.739247 209.828 1.01704 210.232 1.3706C210.637 1.72415 210.839 2.11559 210.839 2.5449C210.839 2.72168 210.813 2.88583 210.763 3.03736L203.224 26.1447C202.997 26.8013 202.505 27.2938 201.747 27.6221C201.015 27.9504 200.207 28.1145 199.323 28.1145C198.464 28.1145 197.643 27.9504 196.86 27.6221C196.103 27.2938 195.648 26.8013 195.497 26.1447L193.527 17.1669L191.557 26.1447C191.406 26.8013 190.938 27.2938 190.156 27.6221C189.398 27.9504 188.59 28.1145 187.731 28.1145Z" fill="#346897"/>
                <path d="M161.915 28.9969C161.167 29.0281 160.428 28.8253 159.795 28.4154C159.57 28.2854 159.38 28.1007 159.241 27.8776C159.103 27.6546 159.02 27.4 159 27.1363V1.82505C159.003 1.55224 159.079 1.28553 159.219 1.05341C159.359 0.821294 159.558 0.632482 159.795 0.507149C160.445 0.147586 161.177 -0.0264554 161.915 0.00325664C162.64 -0.0172261 163.358 0.156468 163.998 0.507155C164.249 0.62061 164.464 0.804491 164.618 1.03764C164.772 1.27079 164.859 1.54373 164.868 1.82505V13.2597C165.274 12.4486 165.873 11.7554 166.61 11.2441C167.467 10.6079 168.505 10.2807 169.563 10.3138C170.716 10.3111 171.837 10.7072 172.743 11.4379C173.758 12.1992 174.566 13.2132 175.09 14.3838C175.707 15.6464 176.019 17.0422 175.999 18.4537V27.1363C175.999 27.4399 175.908 27.7363 175.739 27.9857C175.57 28.2351 175.33 28.4256 175.053 28.5317C174.429 28.8461 173.741 29.0054 173.046 28.9969C172.364 28.9967 171.691 28.8376 171.077 28.5317C170.799 28.4256 170.56 28.2351 170.391 27.9857C170.222 27.7363 170.131 27.44 170.131 27.1363V18.4537C170.131 17.962 170 17.4797 169.752 17.0583C169.507 16.6264 169.17 16.2558 168.768 15.973C168.368 15.6899 167.891 15.5409 167.405 15.5466C166.978 15.553 166.561 15.6733 166.193 15.8955C165.8 16.144 165.474 16.4907 165.247 16.9033C164.98 17.3731 164.849 17.9107 164.868 18.4537V27.1363C164.842 27.4079 164.748 27.668 164.595 27.8919C164.443 28.1157 164.237 28.296 163.998 28.4154C163.375 28.8153 162.65 29.0176 161.915 28.9969Z" fill="#1C274C"/>
                <path d="M149.513 28.9924C147.826 29.059 146.149 28.6874 144.638 27.912C143.443 27.2499 142.475 26.2263 141.864 24.9794C141.274 23.6751 140.979 22.2504 141.001 20.812V18.1881C140.979 16.7498 141.274 15.325 141.864 14.0208C142.475 12.7739 143.443 11.7502 144.638 11.0882C146.122 10.3123 147.775 9.94037 149.438 10.0078C150.542 9.99013 151.642 10.1463 152.7 10.4708C153.522 10.6865 154.289 11.0813 154.95 11.6284C155.193 11.8127 155.393 12.0503 155.536 12.3239C155.678 12.5975 155.76 12.9003 155.775 13.2105C155.759 13.639 155.657 14.0594 155.475 14.4452C155.305 14.9007 155.036 15.31 154.688 15.6414C154.537 15.8023 154.357 15.9298 154.157 16.0161C153.957 16.1024 153.742 16.1456 153.525 16.143C153.212 16.1396 152.903 16.0603 152.625 15.9115C152.288 15.7292 151.936 15.5744 151.575 15.4485C151.042 15.2779 150.484 15.1996 149.925 15.217C149.496 15.1727 149.061 15.2183 148.649 15.3509C148.237 15.4835 147.855 15.7004 147.526 15.9887C147.018 16.5978 146.762 17.3873 146.813 18.1881V20.812C146.762 21.6128 147.018 22.4024 147.526 23.0115C148.001 23.526 148.813 23.7832 149.963 23.7832C150.513 23.8056 151.061 23.7138 151.575 23.5131C151.975 23.333 152.313 23.1658 152.588 23.0115C152.871 22.8362 153.195 22.7429 153.525 22.7414C154.014 22.7288 154.491 22.8926 154.875 23.2044C155.246 23.5073 155.531 23.9071 155.7 24.362C155.887 24.7458 155.99 25.1675 156 25.5968C155.979 25.9204 155.891 26.2357 155.742 26.5218C155.593 26.8078 155.387 27.0581 155.138 27.256C154.451 27.8328 153.661 28.2654 152.813 28.5294C151.741 28.852 150.629 29.008 149.513 28.9924Z" fill="#1C274C"/>
                <path d="M129.519 28.9994C128.029 29.0133 126.561 28.6432 125.259 27.9256C124.006 27.2243 122.942 26.2335 122.158 25.0375C121.402 23.9065 121 22.58 121 21.2236V12.7812C121.026 12.5218 121.119 12.2733 121.269 12.0594C121.42 11.8455 121.623 11.6734 121.859 11.5593C122.474 11.1773 123.189 10.9841 123.914 11.0038C124.627 10.9873 125.329 11.1806 125.932 11.5593C126.168 11.6734 126.372 11.8455 126.522 12.0594C126.672 12.2733 126.765 12.5218 126.791 12.7812V21.2236C126.791 21.6933 126.921 22.1541 127.165 22.5566C127.407 22.9691 127.739 23.3232 128.136 23.5934C128.547 23.861 129.027 24.0027 129.519 24.0007C129.999 24.0062 130.469 23.8638 130.864 23.5934C131.273 23.3372 131.608 22.9799 131.835 22.5566C132.079 22.1541 132.209 21.6933 132.209 21.2236V12.7441C132.229 12.4829 132.319 12.2317 132.47 12.0168C132.621 11.8019 132.828 11.6311 133.068 11.5222C133.694 11.1695 134.403 10.9904 135.123 11.0038C135.845 10.9709 136.56 11.1513 137.178 11.5222C137.411 11.6363 137.61 11.8094 137.754 12.0239C137.899 12.2384 137.983 12.4868 138 12.7441V21.2236C138.004 22.5921 137.601 23.9315 136.842 25.0745C136.063 26.2654 134.997 27.2453 133.741 27.9256C132.453 28.6459 130.997 29.0163 129.519 28.9994Z" fill="#1C274C"/>
                <path d="M108.98 23.7865C109.488 23.7922 109.986 23.6438 110.404 23.3617C110.843 23.0886 111.209 22.718 111.472 22.2804C111.732 21.8328 111.868 21.3273 111.868 20.813V18.1098C111.868 17.6199 111.731 17.1394 111.472 16.7196C111.209 16.282 110.843 15.9114 110.404 15.6383C109.986 15.3562 109.488 15.2078 108.98 15.2135C108.46 15.2114 107.951 15.3591 107.516 15.6383C107.096 15.92 106.744 16.2893 106.488 16.7196C106.253 17.1471 106.131 17.6248 106.132 18.1098V20.813C106.131 21.3227 106.252 21.8254 106.488 22.2804C106.744 22.7107 107.096 23.08 107.516 23.3617C107.951 23.6409 108.46 23.7886 108.98 23.7865ZM108.98 28.9998C107.416 29.0109 105.875 28.625 104.51 27.8799C103.194 27.1341 102.07 26.1037 101.226 24.8678C100.425 23.6601 99.9991 22.2518 100 20.813V18.1098C99.9942 16.6879 100.406 15.2941 101.187 14.0936C102.032 12.8299 103.188 11.7932 104.549 11.0781C105.911 10.363 107.434 9.99244 108.98 10.0002C110.544 9.98909 112.085 10.375 113.45 11.1201C114.784 11.8076 115.915 12.8182 116.734 14.055C117.572 15.2476 118.014 16.6634 118 18.1098V20.813C117.995 22.2446 117.554 23.6425 116.734 24.8291C115.904 26.0765 114.777 27.1098 113.45 27.8413C112.093 28.61 110.549 29.01 108.98 28.9998Z" fill="#1C274C"/>
                <path d="M95 28.9997C94.2899 29.0053 93.5878 28.8481 92.9469 28.5401C92.675 28.4291 92.4425 28.2382 92.2798 27.9923C92.117 27.7463 92.0315 27.4568 92.0344 27.1612V6.36395H86.7876C86.5141 6.3643 86.2458 6.28961 86.0113 6.14791C85.7769 6.0062 85.5852 5.80282 85.4568 5.55963C85.146 4.97059 84.989 4.31159 85.0006 3.6446C84.9975 3.01976 85.1408 2.403 85.4188 1.84447C85.5363 1.58156 85.7298 1.36047 85.974 1.21013C86.2182 1.05979 86.5018 0.987202 86.7876 1.00185H103.212C103.498 0.987201 103.782 1.05979 104.026 1.21013C104.27 1.36047 104.464 1.58155 104.581 1.84447C104.859 2.403 105.003 3.01976 104.999 3.6446C105.011 4.31159 104.854 4.97059 104.543 5.55963C104.42 5.8069 104.229 6.01377 103.994 6.15616C103.758 6.29856 103.487 6.37061 103.212 6.36395H97.9656V27.1612C97.9655 27.4613 97.8745 27.7541 97.7046 28.0005C97.5347 28.247 97.2941 28.4352 97.0151 28.5401C96.3886 28.8507 95.6982 29.0081 95 28.9997Z" fill="#1C274C"/>
                <path d="M72.8521 28.6161C72.1228 28.6436 71.4027 28.4434 70.7881 28.0423C70.5652 27.914 70.3767 27.7317 70.2392 27.5115C70.1018 27.2914 70.0196 27.0401 70 26.7799V1.80056C70.0035 1.53134 70.0786 1.26813 70.2174 1.03905C70.3562 0.809977 70.5535 0.62364 70.7881 0.499949C71.4197 0.147948 72.1327 -0.0238499 72.8521 0.00266409C73.5831 -0.0203994 74.3074 0.150989 74.9536 0.499955C75.203 0.611921 75.4164 0.79339 75.5691 1.02348C75.7218 1.25357 75.8076 1.52293 75.8168 1.80057V16.7959L82.0464 10.6371C82.1933 10.4863 82.369 10.3678 82.5627 10.2888C82.7563 10.2099 82.9637 10.1722 83.1722 10.1781C83.6342 10.1908 84.0806 10.3514 84.4481 10.6371C84.8859 10.9053 85.2581 11.2716 85.5364 11.7082C85.8167 12.0828 85.9743 12.5378 85.9868 13.0088C85.9855 13.1933 85.9472 13.3756 85.8742 13.5444C85.7939 13.7306 85.6792 13.8995 85.5365 14.0416L81.5585 17.7905L86.6998 25.1351C86.8811 25.4069 86.985 25.7247 87 26.0531C86.9791 26.5658 86.7795 27.0541 86.4371 27.4302C86.0777 27.8973 85.6314 28.2873 85.1236 28.5778C84.6788 28.8427 84.1751 28.9875 83.66 28.9986C83.3867 29.0101 83.1151 28.9492 82.8718 28.8218C82.6284 28.6944 82.4216 28.5049 82.2715 28.2718L77.7307 21.4245L75.8168 23.2224V26.7799C75.7905 27.0479 75.6974 27.3046 75.5463 27.5256C75.3952 27.7465 75.1912 27.9244 74.9536 28.0423C74.3238 28.4402 73.5927 28.6398 72.8521 28.6161Z" fill="#1C274C"/>
                <path d="M61.0805 28.9924C59.2808 29.059 57.4927 28.6874 55.8809 27.912C54.6063 27.2499 53.5732 26.2263 52.9212 24.9794C52.2922 23.6751 51.9777 22.2504 52.0012 20.812V18.1881C51.9777 16.7498 52.2922 15.325 52.9212 14.0208C53.5731 12.7739 54.6063 11.7502 55.8809 11.0882C57.4639 10.3123 59.2264 9.94037 61.0005 10.0078C62.1781 9.99013 63.3516 10.1463 64.4803 10.4708C65.3569 10.6865 66.1752 11.0813 66.8801 11.6284C67.1391 11.8127 67.3524 12.0503 67.5046 12.3239C67.6567 12.5975 67.744 12.9003 67.76 13.2105C67.743 13.639 67.6341 14.0594 67.44 14.4452C67.2587 14.9007 66.9713 15.31 66.6001 15.6414C66.4399 15.8023 66.2472 15.9298 66.034 16.0161C65.8207 16.1024 65.5914 16.1456 65.3602 16.143C65.0258 16.1396 64.697 16.0603 64.4003 15.9115C64.0401 15.7292 63.6656 15.5744 63.2804 15.4485C62.7111 15.2779 62.1164 15.1996 61.5205 15.217C61.0619 15.1727 60.5988 15.2183 60.159 15.3509C59.7191 15.4835 59.3115 15.7005 58.9607 15.9887C58.4191 16.5979 58.1463 17.3874 58.2007 18.1882V20.8121C58.1463 21.6129 58.4191 22.4024 58.9607 23.0115C59.4673 23.526 60.3339 23.7832 61.5605 23.7832C62.1468 23.8056 62.7317 23.7138 63.2803 23.5131C63.707 23.3331 64.0669 23.1659 64.3603 23.0115C64.6623 22.8363 65.0078 22.7429 65.3602 22.7414C65.8815 22.7288 66.3909 22.8927 66.8001 23.2044C67.1956 23.5073 67.4995 23.9071 67.68 24.362C67.8798 24.7458 67.9891 25.1675 68 25.5968C67.9772 25.9205 67.8834 26.2357 67.7249 26.5218C67.5663 26.8078 67.3465 27.0581 67.0801 27.256C66.3477 27.8328 65.5052 28.2655 64.6003 28.5294C63.4575 28.852 62.2711 29.008 61.0805 28.9924Z" fill="#1C274C"/>
                <path d="M46.0184 28.9968C45.2911 29.0285 44.5721 28.8225 43.957 28.4062C43.7384 28.2741 43.5535 28.0865 43.4187 27.8599C43.2839 27.6333 43.2033 27.3746 43.1841 27.1068V12.1051C43.1874 11.828 43.2612 11.557 43.3973 11.3212C43.5335 11.0854 43.7269 10.8936 43.9571 10.7663C44.5888 10.4011 45.3008 10.2243 46.0184 10.2545C46.7234 10.2337 47.4212 10.4101 48.0429 10.7663C48.2876 10.8816 48.4968 11.0684 48.6466 11.3052C48.7964 11.542 48.8806 11.8193 48.8895 12.1051V27.1068C48.8638 27.3827 48.7725 27.6469 48.6243 27.8743C48.4761 28.1018 48.276 28.2849 48.0429 28.4062C47.4373 28.8124 46.7329 29.0178 46.0184 28.9968ZM46.0184 6.63199C45.2287 6.65015 44.4632 6.33957 43.8834 5.76574C43.6122 5.52514 43.3928 5.22462 43.2403 4.8848C43.0878 4.54498 43.0058 4.1739 43 3.79701C43.0008 3.41917 43.0805 3.04629 43.2333 2.70565C43.3862 2.36502 43.6083 2.06526 43.8834 1.82829C44.4733 1.27659 45.2351 0.981539 46.0184 1.0014C46.7913 0.976997 47.5432 1.27329 48.1166 1.82829C48.3917 2.06526 48.6138 2.36503 48.7667 2.70567C48.9195 3.04631 48.9992 3.41919 49 3.79703C48.9942 4.17392 48.9122 4.545 48.7597 4.88482C48.6072 5.22464 48.3878 5.52516 48.1166 5.76577C47.8378 6.05146 47.5088 6.27513 47.1486 6.42382C46.7885 6.57251 46.4043 6.64327 46.0184 6.63199Z" fill="#1C274C"/>
                <path d="M31.0198 28.9994C29.4425 29.0133 27.8884 28.6432 26.5099 27.9256C25.183 27.2243 24.0566 26.2335 23.2264 25.0375C22.4261 23.9066 21.9996 22.58 22 21.2236V12.7812C22.0277 12.5218 22.1259 12.2733 22.2851 12.0594C22.4444 11.8455 22.6595 11.6734 22.9099 11.5593C23.5608 11.1773 24.3178 10.9841 25.0857 11.0038C25.8408 10.9874 26.5841 11.1806 27.222 11.5593C27.4724 11.6734 27.6875 11.8455 27.8467 12.0594C28.006 12.2733 28.1041 12.5218 28.1318 12.7812V21.2236C28.132 21.6933 28.2688 22.1541 28.5275 22.5566C28.7841 22.9691 29.1354 23.3232 29.556 23.5934C29.9905 23.861 30.4996 24.0027 31.0198 24.0007C31.5281 24.0062 32.0257 23.8638 32.4439 23.5934C32.8771 23.3372 33.2316 22.9799 33.4725 22.5566C33.7312 22.1541 33.8679 21.6933 33.8681 21.2236V12.7441C33.8891 12.4829 33.9846 12.2317 34.1447 12.0168C34.3047 11.8019 34.5234 11.6311 34.778 11.5222C35.4403 11.1695 36.1919 10.9904 36.9538 11.0038C37.718 10.9709 38.4753 11.1513 39.1296 11.5222C39.3766 11.6363 39.5871 11.8094 39.7399 12.0239C39.8927 12.2384 39.9824 12.4868 40 12.7441V21.2236C40.0044 22.5921 39.5778 23.9315 38.7736 25.0745C37.9491 26.2654 36.8206 27.2453 35.4901 27.9256C34.1271 28.6459 32.5849 29.0163 31.0198 28.9994Z" fill="#1C274C"/>
                <path d="M13.9297 24.0604L13.0836 24.2358L12.5168 24.9223C12.2779 25.1795 12.1128 25.503 12.0408 25.8553C11.9687 26.2075 11.9925 26.5742 12.1096 26.9127L12.967 28.9937L12.9691 29C13.4073 28.8761 13.838 28.7241 14.2588 28.545C15.3208 28.0929 16.2619 27.3731 17 26.4483L15.7648 24.683C15.5458 24.4067 15.2572 24.2015 14.9324 24.0913C14.6076 23.9812 14.26 23.9704 13.9297 24.0604Z" fill="#1C274C"/>
                <path d="M9.82443 27.6099C9.5629 26.9923 9.45686 26.3219 9.5153 25.6555C9.57375 24.9891 9.79496 24.3464 10.1601 23.7819C9.93513 23.8143 9.70809 23.8311 9.48068 23.8323C9.01483 23.8579 8.54853 23.7903 8.11004 23.6336C7.67155 23.4769 7.26999 23.2343 6.92969 22.9206C6.26706 22.1062 5.94873 21.0723 6.04073 20.0335V9.96653C5.94873 8.92765 6.26705 7.89382 6.92969 7.0794C7.26999 6.76566 7.67155 6.52308 8.11004 6.36636C8.54853 6.20964 9.01483 6.14204 9.48069 6.16766C9.94654 6.14204 10.4128 6.20964 10.8513 6.36636C11.2898 6.52308 11.6914 6.76566 12.0316 7.0794C12.372 7.47697 12.6291 7.93665 12.7883 8.43208C12.9474 8.92751 13.0056 9.44895 12.9593 9.96651V20.0334C12.9582 20.3034 12.9413 20.5731 12.9085 20.8412C13.4094 20.8942 13.8823 21.0949 14.2652 21.4168C14.9554 21.3788 15.6447 21.5072 16.2729 21.791C16.901 22.0748 17.4491 22.5052 17.8692 23.0448L18.4267 23.7861C18.8234 22.5742 19.0133 21.3062 18.9889 20.0335V9.96653C19.0775 8.1356 18.6352 6.31758 17.7134 4.72413C16.9242 3.43683 15.7304 2.43679 14.3121 1.87497C12.7755 1.27519 11.1339 0.978314 9.48069 1.00123C7.82751 0.978306 6.18589 1.27518 4.64929 1.87495C3.23979 2.45095 2.05001 3.44759 1.24797 4.72411C0.351894 6.32611 -0.0761556 8.14039 0.011113 9.96651V20.0334C-0.0761542 21.8596 0.351895 23.6739 1.24797 25.2759C2.05001 26.5524 3.23979 27.549 4.64929 28.125C6.18589 28.7248 7.82751 29.0217 9.48069 28.9988C9.80207 28.9988 10.1117 28.9783 10.4221 28.9593L9.82443 27.6099Z" fill="#1C274C"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M18.5945 30.011C18.6301 30.3573 18.5661 30.7066 18.4103 31.0167C18.2545 31.3268 18.0135 31.5845 17.7165 31.7586C17.4195 31.9326 17.0792 32.0157 16.7367 31.9976C16.3942 31.9795 16.0642 31.8611 15.7865 31.6567C15.7648 31.6392 15.7466 31.6177 15.7329 31.5933C15.7178 31.5667 15.7079 31.5372 15.704 31.5067C15.7 31.4762 15.702 31.4452 15.7099 31.4155C15.7177 31.3857 15.7313 31.3579 15.7497 31.3334C15.7682 31.309 15.7912 31.2885 15.8175 31.2731L18.2519 29.8464C18.2781 29.8311 18.3071 29.8211 18.3372 29.817C18.3672 29.813 18.3978 29.8151 18.4271 29.823C18.4564 29.831 18.4838 29.8447 18.5079 29.8635C18.5319 29.8822 18.5521 29.9056 18.5673 29.9322C18.5813 29.9565 18.5905 29.9832 18.5945 30.011ZM19.3547 28.2411L13.8965 31.4399C13.7287 31.5675 13.5276 31.6419 13.3183 31.6539C13.109 31.6658 12.9009 31.6146 12.7202 31.5069C12.5394 31.3991 12.3941 31.2396 12.3024 31.0482C12.2107 30.8569 12.1768 30.6423 12.2049 30.4315C12.2799 30.035 12.2365 29.6248 12.0801 29.2535L11.2167 27.2404C11.0011 26.6864 10.9449 26.0816 11.0547 25.4964C11.1645 24.9113 11.4358 24.3698 11.8369 23.935L11.8086 23.8853C11.7273 23.7423 11.6745 23.5845 11.6533 23.4209C11.6321 23.2573 11.6428 23.091 11.6849 22.9315C11.727 22.7721 11.7996 22.6227 11.8986 22.4917C11.9976 22.3608 12.121 22.2509 12.2618 22.1684C12.4026 22.0859 12.5581 22.0323 12.7193 22.0108C12.8805 21.9892 13.0443 22.0001 13.2014 22.0428C13.3584 22.0855 13.5056 22.1592 13.6346 22.2597C13.7636 22.3602 13.8719 22.4855 13.9532 22.6284L13.9815 22.6781C14.553 22.5429 15.1506 22.5752 15.7048 22.7713C16.2589 22.9673 16.7468 23.3191 17.1117 23.7857L18.3976 25.5512C18.6361 25.8743 18.9643 26.1176 19.3401 26.2499C19.5341 26.3306 19.7002 26.4677 19.8176 26.644C19.935 26.8203 19.9984 27.0278 20 27.2406C20.0015 27.4534 19.9411 27.6618 19.8263 27.8399C19.7115 28.0179 19.5474 28.1575 19.3547 28.2411Z" fill="#1C274C"/>
            </svg>

        </>
    );
};

export default LogoTextIcon;