import {FC} from 'react';

const FacebookIcon:FC = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM16.5634 15.6589V23.8197H13.1868V15.6592H11.4999V12.8469H13.1868V11.1585C13.1868 8.86425 14.1394 7.5 16.8456 7.5H19.0987V10.3126H17.6904C16.6369 10.3126 16.5672 10.7056 16.5672 11.4391L16.5634 12.8466H19.1146L18.8161 15.6589H16.5634Z" fill="#FF600C"/>
        </svg>
    );
};

export default FacebookIcon;