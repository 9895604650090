import {FC} from 'react';
import LayoutTemplate from "../templates/LayoutTemplate";
import MainSection from "../organisms/MainSection/MainSection";
import OnlineSection from "../organisms/OnlineSection/OnlineSection";
import GetAllSection from "../organisms/GetAllSection/GetAllSection";
import ManageSection from "../organisms/ManageSection/ManageSection";
import PaymentSection from "../organisms/PaymentSection/PaymentSection";
import SmartSection from "../organisms/SmartSection/SmartSection";
import FastServiceSection from "../organisms/FastServiceSection/FastServiceSection";
import StatisticsSection from "../organisms/StatisticsSection/StatisticsSection";
import CustomizeSection from "../organisms/CustomizeSection/CustomizeSection";
import PlaceSection from "../organisms/PlaceSection/PlaceSection";
import ReducingSection from "../organisms/ReducingSection/ReducingSection";
import QRSection from "../organisms/QRSection/QRSection";
import ThinkingSection from "../organisms/ThinkingSection/ThinkingSection";
import QuestionsSection from "../organisms/QuestionsSection/QuestionsSection";

const HomePage:FC = () => {
    return (
        <LayoutTemplate>
            <MainSection/>
            <OnlineSection/>
            <GetAllSection/>
            <ManageSection/>
            <PaymentSection/>
            <SmartSection/>
            <FastServiceSection/>
            <StatisticsSection/>
            <PlaceSection/>
            <CustomizeSection/>
            <ReducingSection/>
            <QRSection/>
            <ThinkingSection/>
            <QuestionsSection/>
        </LayoutTemplate>
    );
};

export default HomePage;