import {FC, useEffect, useRef} from 'react';
import './PlaceSection.scss'
import placeImg from '../../../assets/images/place.jpg';

import {useTranslation, Trans} from "react-i18next";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {useDispatchEx} from "../../../hooks/redux";
import InternetIcon from "../../icons/place/InternetIcon";
import FacebookIcon from "../../icons/place/FacebookIcon";
import InstaIcon from "../../icons/place/InstaIcon";
import TiktokIcon from "../../icons/place/TiktokIcon";
import TelegramIcon from "../../icons/place/TelegramIcon";
import WhatsappIcon from "../../icons/place/WhatsappIcon";
import GoogleIcon from "../../icons/place/GoogleIcon";
import AppStoreIcon from "../../icons/place/AppStoreIcon";
import Plate from "../../atoms/Plate/Plate";
import {anime1Finish, anime1Start} from "../../../helpers/animations";
const PlaceSection:FC = () => {
    gsap.registerPlugin(ScrollTrigger);


    const targetSection = useRef(null);
    useEffect(()=>{

        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: targetSection.current,
                start: '+=20% bottom'
            }
        });

        timeline.fromTo(".place-section__inner", anime1Start, anime1Finish, 0.1);
        timeline.fromTo(".place-section__header", anime1Start, anime1Finish, 0.4);
        timeline.fromTo(".place-section__description", anime1Start, anime1Finish, 0.4);
        timeline.fromTo(".place-section__items", anime1Start, anime1Finish, 0.4);

    },[targetSection]);
    const { t } = useTranslation();
    const dispatch = useDispatchEx();
    return (
        <section className={'place-section'} ref={targetSection}>
            <div className="container">
                <div className="wrapper">
                    <div className="row">
                        <div className="col-lg-6 d-none d-lg-flex">
                            <div className="place-section__inner">
                                <div className="place-section__image">
                                    <img src={placeImg} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="place-section__wrapper">
                                <div className="place-section__header">
                                    <h2 className="h2">
                                        <span>PLACE</span> your online restaurant everywhere
                                    </h2>
                                </div>
                                <div className="place-section__description">Tell everyone about your menu and your restaurant with just one click</div>
                                <div className="place-section__image-wrapper">
                                    <div className="place-section__image d-flex d-lg-none">
                                        <img src={placeImg} alt=""/>
                                    </div>
                                    <div className="place-section__items">
                                        <div className="place-section__item">
                                            <Plate
                                                icon={<InternetIcon/>}
                                                title={'Internet'}
                                            />
                                        </div>
                                        <div className="place-section__item">
                                            <Plate
                                                icon={<FacebookIcon/>}
                                                title={'Facebook'}
                                            />
                                        </div>
                                        <div className="place-section__item">
                                            <Plate
                                                icon={<InstaIcon/>}
                                                title={'Instagram'}
                                            />
                                        </div>
                                        <div className="place-section__item">
                                            <Plate
                                                icon={<TiktokIcon/>}
                                                title={'TikTok'}
                                            />
                                        </div>
                                        <div className="place-section__item">
                                            <Plate
                                                icon={<TelegramIcon/>}
                                                title={'Telegram'}
                                            />
                                        </div>
                                        <div className="place-section__item">
                                            <Plate
                                                icon={<WhatsappIcon/>}
                                                title={'WhatsApp'}
                                            />
                                        </div>
                                        <div className="place-section__item">
                                            <Plate
                                                icon={<GoogleIcon/>}
                                                title={'Google play'}
                                            />
                                        </div>
                                        <div className="place-section__item">
                                            <Plate
                                                icon={<AppStoreIcon/>}
                                                title={'App store'}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default PlaceSection;