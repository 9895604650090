import {FC} from 'react';

const DeliveryIcon:FC = () => {
    return (
        <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1_1037)">
                <path d="M12.7947 16.208H33.8565V18.1144H12.7947V16.208Z" fill="#FF600C"/>
                <path d="M12.74 19.3289C12.7365 20.2318 12.7426 24.3706 12.74 25.1817H23.4622C23.7961 25.1817 24.0694 24.9146 24.0694 24.5746C24.0694 22.8078 25.5083 21.3749 27.2751 21.3749C29.0479 21.3749 30.4869 22.8078 30.4869 24.5746C30.4869 24.9146 30.7601 25.1817 31.094 25.1817H33.2494C33.5833 25.1817 33.8565 24.9146 33.8565 24.5746V19.3289H12.74Z" fill="#FF600C"/>
                <path d="M34.2731 7.30877C34.2281 7.37734 11.8874 7.17722 11.9387 7.37748C11.9386 7.37766 11.9386 9.06202 11.9386 9.06202C11.9386 9.10137 11.9577 9.14084 11.9831 9.14084H34.2731C34.3049 9.14084 34.3241 9.10137 34.3241 9.06202V7.37767C34.3241 7.33827 34.3049 7.30877 34.2731 7.30877Z" fill="#FF600C"/>
                <path d="M6.62053 22.2551C3.55557 22.353 3.55628 26.7963 6.62058 26.8937C9.69199 26.7938 9.69127 22.3546 6.62053 22.2551Z" fill="#FF600C"/>
                <path d="M27.2755 22.2551C24.2105 22.353 24.2113 26.7963 27.2755 26.8937C30.3478 26.7973 30.3471 22.3511 27.2755 22.2551Z" fill="#FF600C"/>
                <path d="M6.91149 11.7455L4.149 16.4083L2.93471 16.9183C4.62486 18.8425 2.99986 21.8804 0.5 21.6419C0.500061 21.6419 0.500061 24.5744 0.500061 24.5744C0.500061 24.9144 0.773281 25.1816 1.1072 25.1816H2.80112C3.14112 25.1816 3.40826 24.9144 3.40826 24.5744C3.5607 20.3371 9.68563 20.343 9.83185 24.5745C9.83185 24.9144 10.099 25.1816 10.439 25.1816H11.5258V10.7983H8.56294C7.88898 10.7983 7.25149 11.1626 6.91149 11.7455ZM10.2022 19.5655H9.01829C8.68433 19.5655 8.41115 19.2923 8.41115 18.9583C8.41115 18.6244 8.68433 18.3512 9.01829 18.3512H10.2022C10.9994 18.3664 11 19.5503 10.2022 19.5655ZM10.6454 16.5419C10.6454 16.8819 10.3783 17.1491 10.0383 17.1491H6.62007C6.16095 17.163 5.85349 16.6206 6.09792 16.2323C6.09791 16.2323 8.15613 12.7594 8.15613 12.7594C8.26541 12.5712 8.4597 12.4619 8.67826 12.4619H10.0383C10.3783 12.4619 10.6454 12.729 10.6454 13.069L10.6454 16.5419Z" fill="#FF600C"/>
                <path d="M0.755049 20.4337C2.28507 20.4672 3.01751 18.4146 1.78107 17.489C0.985769 18.0537 0.500061 18.9765 0.500061 19.9965V20.4155C0.583495 20.4289 0.671719 20.436 0.755049 20.4337Z" fill="#FF600C"/>
                <path d="M7.68256 15.9349H9.43113V13.6763H9.02432L7.68256 15.9349Z" fill="#FF600C"/>
                <path d="M12.7401 13.6763H33.8565V14.9938H12.7401V13.6763Z" fill="#FF600C"/>
                <path d="M12.7401 10.3552H33.8565V12.462H12.7401V10.3552Z" fill="#FF600C"/>
            </g>
            <defs>
                <clipPath id="clip0_1_1037">
                    <rect width="34" height="34" fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default DeliveryIcon;