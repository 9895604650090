import {FC} from 'react';

const MaintenanceIcon:FC = () => {
    return (
        <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1_1053)">
                <path d="M18.9038 14.1049C19.3054 13.9941 19.7971 13.4451 20.1482 13.5045C20.1482 13.5046 22.0304 14.1237 22.0304 14.1237L23.9376 10.8282L22.4619 9.51505C22.2586 9.30003 22.4265 8.96223 22.3868 8.69585C22.4271 8.42667 22.2584 8.08979 22.4619 7.87045C22.4619 7.87045 23.9377 6.56352 23.9377 6.56352L22.0304 3.26807L20.1482 3.88717C19.8089 3.94667 19.2995 3.3986 18.9038 3.28693C18.81 3.24307 18.7475 3.16178 18.7225 3.06796L18.3223 1.13574H14.5016L14.1014 3.06796C13.9682 3.39705 13.2489 3.55736 12.9571 3.84345C12.8757 3.89964 12.7694 3.91842 12.6756 3.88717L10.7934 3.26807L8.88617 6.56352L10.362 7.87046C10.5671 8.09222 10.3966 8.42493 10.437 8.69584C10.3941 8.96524 10.57 9.3045 10.362 9.51506C10.362 9.51505 8.88617 10.8282 8.88617 10.8282L10.7934 14.1237L12.6756 13.5046C12.7694 13.4733 12.8757 13.4858 12.957 13.5483C13.2572 13.761 13.5823 13.9486 13.9263 14.1049C14.0138 14.1424 14.0764 14.2237 14.1014 14.3237L14.5016 16.256H18.3223L18.7225 14.3237C18.7475 14.2237 18.81 14.1424 18.9038 14.1049ZM16.4151 13.1919C13.9263 13.1919 11.9065 11.1721 11.9065 8.69584C12.1433 2.73627 20.6837 2.73751 20.9236 8.69589C20.9236 11.1721 18.8976 13.1919 16.4151 13.1919Z" fill="#FF600C"/>
                <path d="M16.415 5.45044C14.6141 5.45044 13.1571 6.90741 13.1571 8.69582C13.3077 12.9908 19.519 12.9903 19.6729 8.69576C19.6729 6.90741 18.2097 5.45044 16.415 5.45044Z" fill="#FF600C"/>
                <path d="M16.8903 21.3837L15.327 18.6823L13.7824 19.1888C13.6886 19.22 13.5823 19.2076 13.5073 19.1513C13.2452 18.8825 12.6358 18.7969 12.5255 18.4635C12.5255 18.4634 12.1941 16.8813 12.1941 16.8813H9.06128L8.72984 18.4634C8.7111 18.5635 8.64854 18.6448 8.55477 18.6885C8.18798 18.7815 7.81817 19.257 7.47297 19.1889C7.47294 19.1888 5.92841 18.6823 5.92841 18.6823L4.36511 21.3837L5.57198 22.4592C5.64702 22.5218 5.68453 22.6218 5.67827 22.7219C5.58154 23.0783 5.79679 23.6647 5.57206 23.9162C5.57198 23.9162 4.36512 24.9917 4.36512 24.9917L5.92842 27.6869L7.47294 27.1804C7.80277 27.1154 8.19577 27.5862 8.5548 27.6868C8.64855 27.7244 8.7111 27.8057 8.72984 27.9058L9.06128 29.4878H12.1941L12.5255 27.9058C12.5443 27.8057 12.6068 27.7244 12.7007 27.6869C13.0544 27.5886 13.4616 27.1141 13.7824 27.1803C13.7825 27.1804 15.327 27.6869 15.327 27.6869L16.8903 24.9917L15.6834 23.9162C15.4836 23.7207 15.6286 23.4276 15.6021 23.1846C15.6301 22.9432 15.4821 22.6433 15.6834 22.4592C15.6834 22.4592 16.8903 21.3837 16.8903 21.3837ZM10.6308 26.9865C8.52351 26.9865 6.8101 25.2857 6.8101 23.1846C6.99005 18.1563 14.2646 18.1511 14.4453 23.1847C14.4453 25.2857 12.7319 26.9865 10.6308 26.9865Z" fill="#FF600C"/>
                <path d="M10.6308 20.6333C7.23383 20.7391 7.23459 25.6306 10.6309 25.7359C14.0191 25.6283 14.0183 20.7404 10.6308 20.6333Z" fill="#FF600C"/>
                <path d="M33.0298 22.8783C33.0641 22.5126 33.0643 22.1248 33.0298 21.759C33.0173 21.6589 33.0611 21.5589 33.1299 21.4963L34.6056 20.1832L32.6984 16.894L30.8162 17.5068C30.7224 17.538 30.6161 17.5256 30.541 17.4693C30.2409 17.2566 29.9095 17.0691 29.5718 16.9127C29.478 16.869 29.4155 16.7877 29.3967 16.6876L28.9903 14.7554H25.1695L24.7693 16.6876C24.7506 16.7877 24.6818 16.869 24.5942 16.9127C24.1796 17.0161 23.7023 17.5717 23.3436 17.5069C23.3436 17.5068 21.4614 16.894 21.4614 16.894L19.5541 20.1832L21.0299 21.4963C21.2362 21.7068 21.0682 22.0463 21.105 22.3155C21.0666 22.5955 21.2376 22.9206 21.03 23.1409C21.0299 23.1409 19.5541 24.4541 19.5541 24.4541L21.4614 27.7433L23.3436 27.1242C23.6853 27.0656 24.1901 27.6125 24.5943 27.7244C24.6818 27.7683 24.7506 27.8496 24.7693 27.9497L25.1696 29.8819H28.9903L29.3967 27.9497C29.4974 27.6248 30.257 27.4501 30.541 27.1679C30.6161 27.1117 30.7224 27.0992 30.8162 27.1242L32.6984 27.7433L34.6056 24.4541L33.1299 23.1409C33.0611 23.0722 33.0173 22.9783 33.0298 22.8783ZM27.083 26.8116C24.5942 26.8116 22.5745 24.798 22.5745 22.3155C22.8085 16.3638 31.3544 16.365 31.5916 22.3156C31.5916 24.798 29.5655 26.8116 27.083 26.8116Z" fill="#FF600C"/>
                <path d="M27.083 19.0762C25.2821 19.0762 23.8251 20.5269 23.8251 22.3153C23.9757 26.6103 30.1869 26.6098 30.3409 22.3153C30.3409 20.5269 28.8777 19.0762 27.083 19.0762Z" fill="#FF600C"/>
                <path d="M22.9885 1.25018C26.2683 3.13843 27.6049 7.54459 25.891 10.9143C25.5358 11.6453 26.6481 12.2 27.0147 11.4632C29.0176 7.52798 27.4574 2.38046 23.6284 0.175431C22.9158 -0.230936 22.2885 0.815888 22.9885 1.25018Z" fill="#FF600C"/>
                <path d="M6.89996 16.0422C7.67056 15.7654 7.27626 14.6032 6.49323 14.8594C2.29584 16.2247 -0.266781 20.9649 0.879487 25.2273C1.09806 26.0209 2.27956 25.7197 2.09157 24.92C1.10999 21.2708 3.30653 17.2106 6.89996 16.0422Z" fill="#FF600C"/>
                <path d="M29.3175 31.9269C25.9268 33.6054 21.4991 32.2823 19.6256 28.9932C19.199 28.2949 18.1405 28.9131 18.5487 29.6296C20.7332 33.4704 25.9094 35.0136 29.8561 33.0553C30.5904 32.6897 30.0661 31.584 29.3175 31.9269Z" fill="#FF600C"/>
            </g>
            <defs>
                <clipPath id="clip0_1_1053">
                    <rect width="34" height="34" fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default MaintenanceIcon;