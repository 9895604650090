import {FC, useEffect, useRef} from 'react';
import './ManageSection.scss'
import manageImg from '../../../assets/images/manage.png'
import {useTranslation, Trans} from "react-i18next";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {useDispatchEx} from "../../../hooks/redux";
import List from "../../atoms/List/List";
import {anime1Finish, anime1Start} from "../../../helpers/animations";
const ManageSection:FC = () => {
    gsap.registerPlugin(ScrollTrigger);


    const targetSection = useRef(null);
    useEffect(()=>{

        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: targetSection.current,
                start: '+=20% bottom'
            }
        });
        timeline.fromTo(".manage-section__wrapper", anime1Start, anime1Finish, 0.1);
        timeline.fromTo(".manage-section__inner", anime1Start, anime1Finish, 0.4);


    },[targetSection]);
    const { t } = useTranslation();
    const dispatch = useDispatchEx();
    return (
        <section className={'manage-section'} ref={targetSection}>
            <div className="container">
                <div className="wrapper">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="manage-section__wrapper">
                                <div className="manage-section__header">
                                    <h2 className="h2">
                                        <span>MANAGE</span> all types of orders and reservations using just one APP
                                    </h2>
                                    <div className="manage-section__list">
                                        <List className={'list--default-big'} items={[
                                            'Fast',
                                            'Smart',
                                            'Comfortable'
                                        ]}/>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ">
                            <div className="manage-section__inner">
                                <div className="manage-section__image">
                                    <img src={manageImg} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default ManageSection;