import {FC} from 'react';

const GoogleIcon:FC = () => {
    return (
        <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.5229 16.6226L21.6241 19.3951L17.2303 15.0288L21.6591 10.6275L26.5229 13.3801C26.8099 13.5424 27.0487 13.778 27.2149 14.0628C27.3811 14.3477 27.4686 14.6715 27.4686 15.0013C27.4686 15.3311 27.3811 15.6549 27.2149 15.9398C27.0487 16.2246 26.8099 16.4603 26.5229 16.6226ZM0.671375 1.15493C0.578611 1.38013 0.531041 1.62138 0.531374 1.86494V28.1365C0.531374 28.4077 0.587624 28.6602 0.686375 28.8865L14.6303 15.0276L0.671375 1.15493ZM15.9303 13.7363L20.0028 9.68877L3.31265 0.243673C2.95491 0.0380902 2.53826 -0.0407476 2.13014 0.0199207L15.9303 13.7363ZM15.9303 16.3201L2.18014 29.9865C2.55264 30.0315 2.94515 29.9665 3.31265 29.7577L19.9678 20.3326L15.9303 16.3201Z" fill="#FF600C"/>
        </svg>
    );
};

export default GoogleIcon;