import React from 'react';

const InstaIcon = () => {
    return (
        <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 22.5308C0 10.1044 10.0736 0.0307617 22.5 0.0307617C34.9264 0.0307617 45 10.1044 45 22.5308C45 34.9572 34.9264 45.0308 22.5 45.0308C10.0736 45.0308 0 34.9572 0 22.5308ZM22.5011 10.5308C19.2421 10.5308 18.8331 10.545 17.5531 10.6033C16.2756 10.6618 15.4036 10.864 14.6405 11.1608C13.8513 11.4673 13.1818 11.8773 12.5148 12.5445C11.8473 13.2115 11.4373 13.881 11.1298 14.6701C10.8323 15.4333 10.6298 16.3056 10.5723 17.5826C10.515 18.8626 10.5 19.2719 10.5 22.5309C10.5 25.7899 10.5145 26.1977 10.5725 27.4777C10.6313 28.7552 10.8335 29.6272 11.13 30.3902C11.4368 31.1795 11.8468 31.849 12.514 32.516C13.1808 33.1835 13.8503 33.5945 14.639 33.901C15.4026 34.1978 16.2748 34.4 17.5521 34.4585C18.8321 34.5168 19.2408 34.531 22.4996 34.531C25.7589 34.531 26.1667 34.5168 27.4467 34.4585C28.7242 34.4 29.5972 34.1978 30.3607 33.901C31.1497 33.5945 31.8182 33.1835 32.485 32.516C33.1525 31.849 33.5625 31.1795 33.87 30.3905C34.165 29.6272 34.3675 28.755 34.4275 27.4779C34.485 26.1979 34.5 25.7899 34.5 22.5309C34.5 19.2719 34.485 18.8628 34.4275 17.5828C34.3675 16.3053 34.165 15.4333 33.87 14.6703C33.5625 13.881 33.1525 13.2115 32.485 12.5445C31.8175 11.877 31.15 11.467 30.36 11.1608C29.5949 10.864 28.7224 10.6618 27.4449 10.6033C26.1649 10.545 25.7574 10.5308 22.4974 10.5308H22.5011Z" fill="#FF7116"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21.4246 12.6933C21.7441 12.6928 22.1006 12.6933 22.5011 12.6933C25.7052 12.6933 26.0849 12.7048 27.3502 12.7623C28.5202 12.8158 29.1552 13.0113 29.5782 13.1755C30.1382 13.393 30.5375 13.653 30.9572 14.073C31.3772 14.4931 31.6372 14.8931 31.8552 15.4531C32.0195 15.8756 32.2152 16.5106 32.2685 17.6806C32.326 18.9456 32.3385 19.3256 32.3385 22.5281C32.3385 25.7307 32.326 26.1107 32.2685 27.3757C32.215 28.5457 32.0195 29.1807 31.8552 29.6032C31.6377 30.1632 31.3772 30.562 30.9572 30.9817C30.5372 31.4017 30.1385 31.6617 29.5782 31.8792C29.1557 32.0442 28.5202 32.2392 27.3502 32.2927C26.0852 32.3502 25.7052 32.3627 22.5011 32.3627C19.2968 32.3627 18.9171 32.3502 17.6521 32.2927C16.4821 32.2387 15.8471 32.0432 15.4238 31.879C14.8638 31.6615 14.4638 31.4015 14.0438 30.9815C13.6238 30.5615 13.3638 30.1625 13.1458 29.6022C12.9815 29.1797 12.7858 28.5447 12.7325 27.3747C12.675 26.1097 12.6635 25.7297 12.6635 22.5251C12.6635 19.3206 12.675 18.9426 12.7325 17.6776C12.786 16.5076 12.9815 15.8726 13.1458 15.4496C13.3633 14.8896 13.6238 14.4896 14.0438 14.0695C14.4638 13.6495 14.8638 13.3895 15.4238 13.1715C15.8468 13.0065 16.4821 12.8115 17.6521 12.7578C18.7591 12.7078 19.1881 12.6928 21.4246 12.6903V12.6933ZM28.9067 14.6858C28.1117 14.6858 27.4667 15.3301 27.4667 16.1253C27.4667 16.9203 28.1117 17.5653 28.9067 17.5653C29.7017 17.5653 30.3467 16.9203 30.3467 16.1253C30.3467 15.3303 29.7017 14.6858 28.9067 14.6858ZM22.5011 16.3683C19.0978 16.3683 16.3386 19.1276 16.3386 22.5309C16.3386 25.9342 19.0978 28.6922 22.5011 28.6922C25.9044 28.6922 28.6627 25.9342 28.6627 22.5309C28.6627 19.1276 25.9044 16.3683 22.5011 16.3683Z" fill="#FF7116"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M22.5011 18.5308C24.7101 18.5308 26.5012 20.3216 26.5012 22.5309C26.5012 24.7399 24.7101 26.5309 22.5011 26.5309C20.2919 26.5309 18.5011 24.7399 18.5011 22.5309C18.5011 20.3216 20.2919 18.5308 22.5011 18.5308Z" fill="#FF7116"/>
        </svg>
    );
};

export default InstaIcon;