import {FC, useEffect, useRef} from 'react';
import './QRSection.scss'

import {useTranslation, Trans} from "react-i18next";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {useDispatchEx} from "../../../hooks/redux";
import QrIcon from "../../icons/QRIcon";

import googleImg from "../../../assets/images/android.png";
import iosImg from "../../../assets/images/ios.png"
import {anime1Finish, anime1Start} from "../../../helpers/animations";

const QRSection:FC = () => {
    gsap.registerPlugin(ScrollTrigger);


    const targetSection = useRef(null);
    useEffect(()=>{

        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: targetSection.current,
                start: '+=20% bottom'
            }
        });

        timeline.fromTo(".qr-section__header", anime1Start, anime1Finish, 0.1);
        timeline.fromTo(".qr-section__image", anime1Start, anime1Finish, 0.4);

    },[targetSection]);
    const { t } = useTranslation();
    const dispatch = useDispatchEx();
    return (
        <section id={'qr-section'} className={'qr-section'} ref={targetSection}>
            <div className="container">
                <div className="wrapper">
                    <div className="qr-section__wrapper">
                        <div className="qr-section__header">
                            <h2 className="h2">
                                <span>HOW</span> your online restaurant could look
                            </h2>
                        </div>
                        <div className="qr-section__image">
                            <span>Scan me</span>
                            <QrIcon/>
                            <div className="qr-section__button">
                                <a className={'link-line--reverse'} target={"_blank"} href="https://picburg.quicktouch.menu">Click me</a>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </section>
    );
};

export default QRSection;