import {FC} from 'react';

const TiktokIcon:FC = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15 29.7421C23.213 29.7421 29.8711 23.0841 29.8711 14.8711C29.8711 6.65801 23.213 0 15 0C6.78691 0 0.128906 6.65801 0.128906 14.8711C0.128906 23.0841 6.78691 29.7421 15 29.7421ZM18.366 6.99814C18.3657 7.94296 18.7088 8.85569 19.3314 9.56641C20.57 10.9963 22.2822 10.9143 22.2822 10.9143V13.7285C20.8772 13.7309 19.5072 13.2912 18.366 12.4716V18.2001C18.366 21.069 16.0436 23.3913 13.1839 23.3913C10.5154 23.3913 8.00179 21.2329 8.00179 18.2001C8.00179 15.0854 10.7522 12.6447 13.8943 13.0636V15.9415C12.3369 15.4588 10.8251 16.6337 10.8251 18.2001C10.8251 19.5116 11.8998 20.568 13.193 20.568C13.8045 20.568 14.3921 20.3306 14.8321 19.9059C15.2721 19.4812 15.5301 18.9023 15.5518 18.2912V6.99814H18.366Z" fill="#FF600C"/>
        </svg>
    );
};

export default TiktokIcon;