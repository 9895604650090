import React from 'react';

const FacebookIcon = () => {
    return (
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.0180664 22.5308C0.0180664 10.1044 10.0917 0.0307617 22.5181 0.0307617C34.9445 0.0307617 45.0181 10.1044 45.0181 22.5308C45.0181 34.9572 34.9445 45.0308 22.5181 45.0308C10.0917 45.0308 0.0180664 34.9572 0.0180664 22.5308ZM24.8633 35.7603V23.5191H28.2424L28.6902 19.3007H24.8633L24.869 17.1894C24.869 16.0891 24.9736 15.4996 26.5538 15.4996H28.6663V11.2808H25.2867C21.2273 11.2808 19.7984 13.3271 19.7984 16.7685V19.3012H17.2681V23.5196H19.7984V35.7603H24.8633Z" fill="#FF7116"/>
        </svg>
    );
};

export default FacebookIcon;