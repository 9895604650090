import {FC, useEffect, useRef} from 'react';
import './CustomizeSection.scss'
import customizeImg from '../../../assets/images/customize.png';

import {useTranslation, Trans} from "react-i18next";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {useDispatchEx} from "../../../hooks/redux";
import {anime1Finish, anime1Start} from "../../../helpers/animations";
const CustomizeSection:FC = () => {
    gsap.registerPlugin(ScrollTrigger);


    const targetSection = useRef(null);
    useEffect(()=>{

        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: targetSection.current,
                start: '+=20% bottom'
            }
        });

        timeline.fromTo(".customize-section__wrapper", anime1Start, anime1Finish, 0.1);
        timeline.fromTo(".customize-section__inner", anime1Start, anime1Finish, 0.4);

    },[targetSection]);
    const { t } = useTranslation();
    const dispatch = useDispatchEx();
    return (
        <section className={'customize-section'} ref={targetSection}>
            <div className="container">
                <div className="wrapper">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="customize-section__wrapper">
                                <div className="customize-section__header">
                                    <h2 className="h2">
                                        <span>CUSTOMIZE</span> your online restaurant as you need
                                    </h2>
                                </div>
                                <div className="customize-section__description">Change brand colors\ activating & deactivating the required functions at the right moment</div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="customize-section__inner">
                                <div className="customize-section__image">
                                    <img src={customizeImg} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default CustomizeSection;