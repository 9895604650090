import {FC, useEffect, useRef} from 'react';
import './SmartSection.scss'
import smartImg from '../../../assets/images/smart.png';
import smartQRImg from '../../../assets/images/smartQR.png';

import {useTranslation, Trans} from "react-i18next";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {useDispatchEx} from "../../../hooks/redux";
import {anime1Finish, anime1Start} from "../../../helpers/animations";
const SmartSection:FC = () => {
    gsap.registerPlugin(ScrollTrigger);


    const targetSection = useRef(null);
    useEffect(()=>{

        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: targetSection.current,
                start: '+=20% bottom'
            }
        });
        timeline.fromTo(".smart-section__wrapper", anime1Start, anime1Finish, 0.1);
        timeline.fromTo(".smart-section__inner", anime1Start, anime1Finish, 0.4);


    },[targetSection]);
    const { t } = useTranslation();
    const dispatch = useDispatchEx();
    return (
        <section className={'smart-section'} ref={targetSection}>
            <div className="container">
                <div className="wrapper">
                    <div className="row">

                        <div className="col-lg-6">
                            <div className="smart-section__wrapper">
                                <div className="smart-section__header">
                                    <h2 className="h2">
                                        Smart <span>online</span> & QR <span>menu</span>
                                    </h2>
                                </div>
                                <div className="smart-section__description">Now your menu works for you</div>
                                <div className="smart-section__content">
                                    All management, data and statistics are collected on a one single working view with online orders and payments.
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ">
                            <div className="smart-section__inner">
                                <div className="smart-section__image">
                                    <img src={smartImg} alt=""/>
                                    <div className="smart-section__qr">
                                        <img src={smartQRImg} alt=""/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default SmartSection;