import {FC} from 'react';

const InternetIcon:FC = () => {
    return (
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1_1000)">
                <path d="M22.04 18C22.16 17.01 22.25 16.02 22.25 15C22.25 13.98 22.16 12.99 22.04 12H27.11C27.35 12.96 27.5 13.965 27.5 15C27.5 16.035 27.35 17.04 27.11 18M19.385 26.34C20.285 24.675 20.975 22.875 21.455 21H25.88C24.4268 23.5024 22.1212 25.398 19.385 26.34ZM19.01 18H11.99C11.84 17.01 11.75 16.02 11.75 15C11.75 13.98 11.84 12.975 11.99 12H19.01C19.145 12.975 19.25 13.98 19.25 15C19.25 16.02 19.145 17.01 19.01 18ZM15.5 26.94C14.255 25.14 13.25 23.145 12.635 21H18.365C17.75 23.145 16.745 25.14 15.5 26.94ZM9.5 9H5.12C6.55828 6.49082 8.86219 4.59223 11.6 3.66C10.7 5.325 10.025 7.125 9.5 9ZM5.12 21H9.5C10.025 22.875 10.7 24.675 11.6 26.34C8.86794 25.3975 6.56727 23.5016 5.12 21ZM3.89 18C3.65 17.04 3.5 16.035 3.5 15C3.5 13.965 3.65 12.96 3.89 12H8.96C8.84 12.99 8.75 13.98 8.75 15C8.75 16.02 8.84 17.01 8.96 18M15.5 3.045C16.745 4.845 17.75 6.855 18.365 9H12.635C13.25 6.855 14.255 4.845 15.5 3.045ZM25.88 9H21.455C20.9855 7.14219 20.2904 5.34884 19.385 3.66C22.145 4.605 24.44 6.51 25.88 9ZM15.5 0C7.205 0 0.5 6.75 0.5 15C0.5 18.9782 2.08035 22.7936 4.8934 25.6066C6.28628 26.9995 7.93986 28.1044 9.75975 28.8582C11.5796 29.612 13.5302 30 15.5 30C19.4782 30 23.2936 28.4196 26.1066 25.6066C28.9196 22.7936 30.5 18.9782 30.5 15C30.5 13.0302 30.112 11.0796 29.3582 9.25975C28.6044 7.43986 27.4995 5.78628 26.1066 4.3934C24.7137 3.00052 23.0601 1.89563 21.2403 1.14181C19.4204 0.387987 17.4698 0 15.5 0Z" fill="#FF600C"/>
            </g>
            <defs>
                <clipPath id="clip0_1_1000">
                    <rect width="30" height="30" fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default InternetIcon;