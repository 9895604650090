import {FC, useEffect, useRef} from 'react';
import './StatisticsSection.scss'
import statisticsImg from '../../../assets/images/statistics.png';

import {useTranslation, Trans} from "react-i18next";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {useDispatchEx} from "../../../hooks/redux";
import {anime1Finish, anime1Start} from "../../../helpers/animations";
const StatisticsSection:FC = () => {
    gsap.registerPlugin(ScrollTrigger);


    const targetSection = useRef(null);
    useEffect(()=>{

        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: targetSection.current,
                start: '+=20% bottom'
            }
        });

        timeline.fromTo(".statistics-section__wrapper", anime1Start, anime1Finish, 0.1);
        timeline.fromTo(".statistics-section__inner", anime1Start, anime1Finish, 0.4);


    },[targetSection]);
    const { t } = useTranslation();
    const dispatch = useDispatchEx();
    return (
        <section className={'statistics-section'} ref={targetSection}>
            <div className="container">
                <div className="wrapper">
                    <div className="row">
                        <div className="col-lg-6 ">
                            <div className="statistics-section__wrapper">
                                <div className="statistics-section__header">
                                    <h2 className="h2">
                                        Statistics and analytics
                                    </h2>
                                </div>
                                <div className="statistics-section__description">Individual accounting of all payments and orders for each of your employees</div>
                                <div className="statistics-section__content">
                                    Orders/payments/tips/deposits all in one place
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ">
                            <div className="statistics-section__inner">
                                <div className="statistics-section__image">
                                    <img src={statisticsImg} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default StatisticsSection;