import {FC, useEffect, useRef} from 'react';
import './ThinkingSection.scss'
import thinkImg from '../../../assets/images/thinking.png';

import {useTranslation, Trans} from "react-i18next";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {useDispatchEx} from "../../../hooks/redux";
import ArrowIcon from "../../icons/ArrowIcon";
import {anime1Finish, anime1Start} from "../../../helpers/animations";
const ThinkingSection:FC = () => {
    gsap.registerPlugin(ScrollTrigger);


    const targetSection = useRef(null);
    useEffect(()=>{

        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: targetSection.current,
                start: '+=20% bottom'
            }
        });

        timeline.fromTo(".thinking-section__inner", anime1Start, anime1Finish, 0.1);
        timeline.fromTo(".thinking-section__wrapper", anime1Start, anime1Finish, 0.4);

    },[targetSection]);
    const { t } = useTranslation();
    const dispatch = useDispatchEx();
    return (
        <section className={'thinking-section'} ref={targetSection}>
            <div className="container">
                <div className="wrapper">
                    <div className="row">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="thinking-section__inner">
                                <div className="thinking-section__image">
                                    <img src={thinkImg} alt=""/>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thinking-section__wrapper">
                                <div className="thinking-section__header">
                                    <h2 className="h2">
                                        Are you still thinking?
                                    </h2>
                                </div>
                                <div className="thinking-section__description">Take Quick Touch Waiter <span>FREE</span></div>
                                <div className="thinking-section__description">Spend no more than <span>30 minutes</span> for onboarding and content filling</div>
                                <div className="thinking-section__button">
                                    <a className={'link-line'} href={'#'}><ArrowIcon/> Enjoy & Cook</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </section>
    );
};

export default ThinkingSection;