import {FC, useState} from 'react';
import i18next from "i18next";
import './SwitchLanguage.scss'
import WorldIcon from "../../icons/WorldIcon";

interface ILang {
    name:string;
    lng: string;
}

interface ISwitchLanguage {

}

const SwitchLanguage:FC<ISwitchLanguage> = ({}) => {
    const lang = [
        {name:"English",lng:'en'},
        {name:"German",lng:'de'},
        {name:"Lithuanian",lng:'lt'},
        {name:"Latvian",lng:'lv'},
        {name:"Estonian",lng:'et'}
    ];
    const loadLang = localStorage.getItem('qtm-lang') ? localStorage.getItem('qtm-lang') : 'en';
    const currentLang = lang.filter(item=>item.lng === loadLang)[0];
    const [active,setActive] = useState(currentLang?.name);
    const changeLanguage = (item:ILang)=>{
        setActive(item.name);
        localStorage.setItem('qtm-lang',item.lng);
        i18next.changeLanguage(item.lng)
    };
    return (
        <div className={'switch-lang'}>
            <a className={'switch-lang__active'}>{active} <WorldIcon/>
                <ul className={'switch-lang__list'}>
                    {lang.filter(item=>item.name !== active).map((item,index)=><li key={index}><a className={'link-line'} onClick={()=>changeLanguage(item)} >{item.name}</a></li>)}
                </ul>
            </a>

        </div>
    );
};

export default SwitchLanguage;